import { Grid } from '@mui/material'
import React from 'react'
import { Column } from '../../../table/ReactTable'
import CostDetailsTableSelectHeaders from './CostDetailsTableSelectHeaders'
import ExportCSV, { Data } from '../../../table/ExportCsv'

export interface CostDetailsTableFiltersProps {
  columnVisibility: Record<string, boolean>
  setColumnVisibility: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  columns: Column[]
  data: Data[]
}

export const CostDetailsTableFilters: React.FC<CostDetailsTableFiltersProps> = ({
  columnVisibility,
  setColumnVisibility,
  columns: headers,
  data,
  children,
}): JSX.Element => {
  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item>
        {children}
        <ExportCSV data={data} columns={headers} />
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <CostDetailsTableSelectHeaders
            onChange={setColumnVisibility}
            hiddenColumns={columnVisibility}
            options={headers}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
