import theme from '../styles/theme'
import { AdvertiserCostDetails, PublisherCostDetails } from '../__generated__/graphql'

export type StatsConfKeys = keyof AdvertiserCostDetails | keyof PublisherCostDetails
type Stats = Record<
  StatsConfKeys,
  { color: string; style?: 'decimal' | 'currency' | 'percent'; name: string; family: string }
>
export const StatsConf: Partial<Stats> = {
  displays: {
    color: theme.palette.secondary.light,
    style: 'decimal',
    name: 'displays',
    family: 'impression',
  },
  impressions: {
    color: theme.palette.secondary.main,
    style: 'decimal',
    name: 'impressions',
    family: 'impression',
  },
  clicks: {
    color: theme.palette.info.main,
    style: 'decimal',
    name: 'clicks',
    family: 'userEvent',
  },
  ctr: {
    color: theme.palette.success.main,
    style: 'percent',
    name: 'ctr',
    family: 'costRatio',
  },
  cpc: {
    color: '#ffde00',
    style: 'currency',
    name: 'cpc',
    family: 'costStats',
  },
  rpm: {
    color: '#6EC0E2',
    style: 'currency',
    name: 'rpm',
    family: 'costStats',
  },
  cpm: {
    color: '#F7915D',
    style: 'currency',
    name: 'cpm',
    family: 'costStats',
  },
  commissions: {
    color: theme.palette.error.main,
    style: 'currency',
    name: 'commissions',
    family: 'commissions',
  },
  costs: {
    color: theme.palette.error.main,
    style: 'currency',
    name: 'costs',
    family: 'costs',
  },
  leads: {
    color: 'FFF',
    style: 'decimal',
    name: 'leads',
    family: 'userEvent',
  },
  orders: {
    color: 'FFF',
    style: 'decimal',
    name: 'orders',
    family: 'userEvent',
  },
  cpl: {
    color: 'FFF',
    style: 'currency',
    name: 'cpl',
    family: 'costStats',
  },
  cpo: {
    color: 'FFF',
    style: 'currency',
    name: 'cpo',
    family: 'costStats',
  },
  lr: {
    color: 'FFF',
    style: 'percent',
    name: 'LR%',
    family: 'costRatio',
  },
  or: {
    color: 'FFF',
    style: 'percent',
    name: 'OR',
    family: 'costStats',
  },
  pageViews: {
    color: 'FFF',
    style: 'decimal',
    name: 'pageViews',
    family: 'analytics',
  },
  bounceRate: {
    color: 'FFF',
    style: 'percent',
    name: 'bounceRate',
    family: 'analytics',
  },
  totalUsers: {
    color: 'FFF',
    style: 'decimal',
    name: 'totalUsers',
    family: 'analytics',
  },
  newUsers: {
    color: 'FFF',
    style: 'decimal',
    name: 'newUsers',
    family: 'analytics',
  },
  sessions: {
    color: 'FFF',
    style: 'decimal',
    name: 'sessions',
    family: 'analytics',
  },
  engagedSessions: {
    color: 'FFF',
    style: 'decimal',
    name: 'engagedSessions',
    family: 'analytics',
  },
  cpv: {
    color: 'FFF',
    style: 'currency',
    name: 'cpv',
    family: 'analytics',
  },
  revenue: {
    color: 'FFF',
    style: 'currency',
    name: 'revenue',
    family: 'costStats',
  },
  engagementRate: {
    color: 'FFF',
    style: 'percent',
    name: 'engagementRate',
    family: 'analytics',
  },
}
