import dayjs from 'dayjs'
import React from 'react'
import { useMemo } from 'react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import {
  createNumericColumn,
  FirstColumn,
  formatNameWithAdditionalInfo,
  formatNumber,
  GroupColumnsType,
  HookOptions,
  makeLink,
  OtherColumn,
} from './useStatsColumns'
import { AdvertiserCostDetails } from '../../../__generated__/graphql'
import LinkCell from '../../../components/layout/cells/LinkCell'
import StatusCell from '../../../components/layout/cells/StatusCell'
import CostDetailsTableSelectField from '../../../components/stats/table/header/CostDetailsTableSelectField'
import ExpandCellWrapper from '../../../components/table/ExpandCellWrapper'
import {
  AdGroupsCostDetailsTableItem,
  AdCopyCostDetailsTableItem,
  ADGROUP_DATA_TYPE,
  BASE_STAT_DATA_TYPE,
  CAMPAIGN_DATA_TYPE,
} from '../../../interfaces/stats'
import { AdCopyPreviewCell } from '../../../pages/adgroup/adcopy/previewTable/AdCopyPreviewCell'
import { useAppTranslations } from '../useAppTranslations'
import { Column } from '../../../components/table/ReactTable'
import { useUserPermissions } from '../../../pages/company/currentCompanyDisplayedContext'
import { StatsConf } from '../../statsConf'

const adGroupColumnHelper = createColumnHelper<AdGroupsCostDetailsTableItem>()
const adCopyColumnHelper = createColumnHelper<AdCopyCostDetailsTableItem>()

const PERFORMANCE_METRICS = {
  group: 1,
  columnsName: ['impressions', 'ctr', 'clicks'],
}

const COST_METRICS = {
  group: 2,
  columnsName: ['cpc', 'cpm', 'costs'],
}

const LEAD_METRICS = {
  group: 3,
  columnsName: ['leads', 'lr', 'cpl'],
}

const ORDER_METRICS = {
  group: 4,
  columnsName: ['orders', 'or', 'cpo'],
}

const ANALYTICS_METRICS = {
  group: 6,
  columnsName: [
    'pageViews',
    'bounceRate',
    'totalUsers',
    'newUsers',
    'sessions',
    'engagedSessions',
    'cpv',
    'revenue',
    'engagementRate',
  ],
}

type TotalCostDetails = AdvertiserCostDetails & { name: string }

const useNameColumn = <T extends string>(
  dataType: string,
  total: TotalCostDetails,
  options: HookOptions<T>
): Column => {
  const { dateFormat, selectedDataType, selectedDataTypeChange, allDataTypes } = options
  const columnHelper = createColumnHelper<
    AdvertiserCostDetails & { name: string; id?: string; additionalInfo?: string }
  >()
  const adCopyColumnHelper = createColumnHelper<AdCopyCostDetailsTableItem>()

  return useMemo(() => {
    if (selectedDataType === ADGROUP_DATA_TYPE.ADCOPIES)
      return adCopyColumnHelper.accessor('name', {
        header: () => (
          <CostDetailsTableSelectField
            defaultValue={selectedDataType}
            onChange={selectedDataTypeChange}
            options={allDataTypes}
          />
        ),
        enableSorting: true,
        cell: ({ row }) => (
          <LinkCell
            value={<AdCopyPreviewCell item={row.original} />}
            target={makeLink({ type: selectedDataType, id: row.original?.id, row })}
            label={'adcopy'}
          />
        ),
        footer: total.name,
      }) as Column

    return columnHelper.accessor('name', {
      header: () => (
        <CostDetailsTableSelectField
          defaultValue={selectedDataType}
          onChange={selectedDataTypeChange}
          options={allDataTypes}
        />
      ),
      enableSorting: true,
      cell: ({ getValue, row }) => {
        const dateOrName = getValue()
        return (
          <FirstColumn>
            <ExpandCellWrapper row={row}>
              {dayjs(dateOrName).isValid() && selectedDataType === BASE_STAT_DATA_TYPE.TIMESERIES ? (
                <>{dayjs(dateOrName).format(dateFormat)}</>
              ) : (
                <LinkCell
                  value={formatNameWithAdditionalInfo(dateOrName, row.original?.additionalInfo)}
                  target={makeLink({ type: selectedDataType, id: row.original?.id, row })}
                />
              )}
            </ExpandCellWrapper>
          </FirstColumn>
        )
      },
      footer: total.name,
    }) as Column
  }, [dataType, total])
}

const useNumericColumnsGroup = (
  group: GroupColumnsType,
  total: TotalCostDetails
): ColumnDef<AdvertiserCostDetails>[] => {
  const t = useAppTranslations()
  return useMemo(
    () =>
      group.columnsName.map((columnName) =>
        createNumericColumn<AdvertiserCostDetails>(
          columnName,
          t(`stats.customDisplay.advertiser.${columnName}`),
          group.group,
          total
        )
      ),
    [group, total]
  )
}

const useAdditionalColumns = (dataType: string, total: TotalCostDetails): Column[] => {
  const t = useAppTranslations()

  return useMemo(() => {
    switch (dataType) {
      case CAMPAIGN_DATA_TYPE.ADGROUPS:
        return [
          adGroupColumnHelper.accessor('isActive', {
            header: () => <OtherColumn>{t('stats.table.isActive') as string}</OtherColumn>,
            cell: (info) => <StatusCell isActive={info.getValue()} />,
            footer: 'n/a',
          }),
          adGroupColumnHelper.accessor('dailyBudget', {
            header: () => <OtherColumn>{t('stats.table.dailyBudget') as string}</OtherColumn>,
            cell: (info) => (
              <>{info.getValue() ? formatNumber(info.getValue(), 'currency') : t('commons.noValueYet')}</>
            ),
            footer: 'n/a',
          }),
        ] as Column[]
      case ADGROUP_DATA_TYPE.ADCOPIES:
        return [
          adCopyColumnHelper.accessor('status', {
            header: () => <OtherColumn>{t('stats.table.status') as string}</OtherColumn>,
            cell: (info) => <StatusCell status={info.getValue()} />,
            footer: 'n/a',
          }),
        ] as Column[]
      default:
        return []
    }
  }, [dataType, total])
}

export const useAdvertiserStatsColumns = <T extends string>(
  dataType: string,
  total: TotalCostDetails,
  options: HookOptions<T>
): Column[] => {
  const permissions = useUserPermissions()
  const nameColumn = useNameColumn(dataType, total, options)
  const performanceColumns = useNumericColumnsGroup(PERFORMANCE_METRICS, total)
  const costColumns = useNumericColumnsGroup(COST_METRICS, total)
  const leadColumns = useNumericColumnsGroup(LEAD_METRICS, total)
  const orderColumns = useNumericColumnsGroup(ORDER_METRICS, total)
  const additionalColumns = useAdditionalColumns(dataType, total)

  // TODO: should be translated
  let additionnalColumns: ColumnDef<AdvertiserCostDetails>[] = []
  if ((permissions.superAdmin && dataType === ADGROUP_DATA_TYPE.ADCOPIES) || dataType === ADGROUP_DATA_TYPE.TIMESERIES)
    additionnalColumns = ANALYTICS_METRICS.columnsName.map((columnName) =>
      createNumericColumn<AdvertiserCostDetails>(columnName, columnName, ANALYTICS_METRICS.group, total)
    )

  return [
    nameColumn,
    ...additionalColumns,
    ...performanceColumns,
    ...costColumns,
    ...leadColumns,
    ...orderColumns,
    ...additionnalColumns,
  ] as Column[]
}
