import { createTheme, PaletteOptions } from '@mui/material/styles'
import { Theme } from '@mui/material/styles'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    header: string
    darkHeader: string
  }

  interface PaletteOptions {
    header: string
    darkHeader: string
  }
}

export const colors: PaletteOptions = {
  primary: {
    light: '#EFF0F2',
    main: '#42a5f5',
    dark: '#2962FF',
    contrastText: '#FFF',
  },
  secondary: {
    light: 'rgba(128, 54, 144, .5)',
    main: '#8135A3',
    dark: '#5A1E74',
    contrastText: '#FFF',
  },
  success: {
    main: '#a0c51b',
  },
  error: {
    main: '#E43D3D',
  },
  warning: {
    main: '#ffc000',
  },
  header: '#EFF0F2',
  divider: '#e0e0e0',
  darkHeader: '#455161',
}

const headingDefaultFontSize = 2

export const theme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: ['"Open Sans"', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    h1: {
      fontSize: `${headingDefaultFontSize}rem`,
    },
    h2: {
      fontSize: `${0.9 * headingDefaultFontSize}rem`,
    },
    h3: {
      fontSize: `${0.8 * headingDefaultFontSize}rem`,
    },
    h4: {
      fontSize: `${0.7 * headingDefaultFontSize}rem`,
    },
    h5: {
      fontSize: `${0.65 * headingDefaultFontSize}rem`,
    },
    h6: {
      fontSize: `${0.6 * headingDefaultFontSize}rem`,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: `1.8rem`,
    },
    subtitle2: {
      fontSize: `1.4rem`,
    },
    body1: {
      fontSize: `1.4rem`,
    },
    body2: {
      fontSize: `1.2rem`,
    },
    caption: {
      fontSize: '1.1rem',
    },
  },
  palette: colors,
  shape: {
    borderRadius: 3,
  },
})
