import { TableContainer, styled } from '@mui/material'
import React, { useState } from 'react'
import { TableItem } from '../../layout/Datatable'
import ReactTable, { Column } from '../../table/ReactTable'
import { CostDetailsTableFilters } from './header/CostDetailsTableFilters'

const StyledContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

interface CostDetailsTableProps<T extends TableItem> {
  toDisplay: T[]
  columns: Column[]
  defaultSort?: Array<{ desc: boolean; id: string }>
}

export const CostDetailsTable: React.FC<CostDetailsTableProps<TableItem>> = ({
  columns,
  toDisplay,
  defaultSort = [],
  children,
}): JSX.Element => {
  const [columnVisibility, setColumnVisibility] = useState(
    columns
      .filter((column) => column.accessorKey !== 'name')
      .reduce((acc, header) => ({ ...acc, [header.accessorKey]: true }), {})
  )

  return (
    <StyledContainer>
      <CostDetailsTableFilters
        setColumnVisibility={setColumnVisibility}
        columnVisibility={columnVisibility}
        columns={columns}
        data={toDisplay}
      >
        {children}
      </CostDetailsTableFilters>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 165px)' }}>
        <ReactTable
          columns={columns}
          data={toDisplay}
          hiddenColumns={columnVisibility}
          footerTop
          defaultSort={defaultSort}
          useGroupStyle
          darkHeader
          stickyHeader
        />
      </TableContainer>
    </StyledContainer>
  )
}
