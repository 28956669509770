import React from 'react'
import { object, string } from 'yup'
import { FormikStepProps } from '../../../../components/form/wizzard/FormikStepper'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import mapEnumForSelect from '../../../../utils/mapEnumForSelect'
import {
  ChannelType,
  Iab,
  Network,
  PositionInput,
  PositionType,
  SubChannelType,
  useGetCompanyDetailsQuery,
  useWebsiteQuery,
} from '../../../../__generated__/graphql'
import { Grid } from '@mui/material'
import InputField from '../../../../components/form/InputField'
import SelectField from '../../../../components/form/SelectField'
import MenuItem from '@mui/material/MenuItem'
import { MaybeFormik } from '../../../../interfaces/maybeFormik'
import { useField } from 'formik'
import Loader from '../../../../components/loader/Loader'
import IabAutoCompleteField from '../../../../components/form/IABAutoCompleteField'

export const summarySchema = object().shape({
  name: string().min(3).required('commons.required'),
  iabCategory: string().required('commons.required'),
  channelType: string().required('commons.required'),
  subchannelType: string().required('commons.required'),
  network: string().required('commons.required'),
})

export interface CreatePositionSummaryInterface extends Partial<Record<keyof PositionInput, unknown>> {
  name: string
  iabCategory: Iab
  positionType: MaybeFormik<PositionType>
  channelType: MaybeFormik<ChannelType>
  subchannelType: MaybeFormik<SubChannelType>
  network: MaybeFormik<Network>
}

const CreatePositionDetailsStep: React.FC<FormikStepProps> = () => {
  const t = useAppTranslations()

  const positionTypes = mapEnumForSelect(PositionType)
  const channelTypes = mapEnumForSelect(ChannelType)
  const subChannelTypes = mapEnumForSelect(SubChannelType)
  const networks = mapEnumForSelect(Network)
  const [websiteField] = useField('websiteId')
  const { data: websiteData, loading: loadingWebsite } = useWebsiteQuery({ variables: { id: websiteField.value } })
  const { data: companyData, loading: loadingCompany } = useGetCompanyDetailsQuery({
    variables: { slug: websiteData?.website.publisher.slug },
  })

  if (loadingWebsite || loadingCompany) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <InputField type={'text'} name={'name'} label={t('commons.fullName')} required variant="outlined" />
      </Grid>
      <Grid item xs>
        <IabAutoCompleteField name={'iabCategory'} label={t('position.summary.iabCategory')} required />
      </Grid>
      <Grid item xs>
        <SelectField name={'positionType'} label={t('position.summary.positionType')} variant="outlined">
          {positionTypes.map((type, i) => (
            <MenuItem value={type.value} key={i}>
              {`${t('enum.PositionType.' + type.label)}`}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      {companyData?.company.userPermissions.web && (
        <Grid item xs>
          <SelectField
            name={'channelType'}
            label={t('position.summary.channelType')}
            required
            variant="outlined"
            withNone={false}
          >
            {channelTypes.map((type, i) => (
              <MenuItem value={type.value} key={i}>
                {`${t('enum.ChannelType.' + type.label)}`}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      )}
      <Grid item xs>
        <SelectField
          name={'subchannelType'}
          label={t('position.summary.subchannelType')}
          required
          variant="outlined"
          withNone={false}
        >
          {subChannelTypes.map((type, i) => (
            <MenuItem value={type.value} key={i}>
              {`${t('enum.SubChannelType.' + type.label)}`}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
      {companyData?.company.userPermissions.web && (
        <Grid item xs>
          <SelectField name={'network'} label={t('position.summary.network')} required variant="outlined">
            {networks.map((media, i) => (
              <MenuItem value={media.value} key={i}>
                {`${t('enum.Network.' + media.label)}`}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      )}
    </Grid>
  )
}

export default CreatePositionDetailsStep
