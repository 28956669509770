import { ApolloError } from '@apollo/client'
import { Stepper, Step, StepLabel, Grid, Button, CircularProgress, Box, IconProps, Stack } from '@mui/material'
import { Alert } from '@mui/material'
import { Form, Formik, FormikConfig, FormikValues } from 'formik'
import React, { Dispatch, Fragment, SetStateAction, useCallback } from 'react'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { ObjectSchema } from 'yup'
import { ObjectShape } from 'yup/lib/object'
import SectionTitle from '../SectionTitle'

export interface FormikStepProps extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string
  validationSchema?: ObjectSchema<ObjectShape>
}

export interface actionButton {
  startIcon: IconProps
  onClick?: () => void | undefined
  label: string
  testingId?: string
}
interface FormikStepperProps<Values> extends FormikConfig<Values> {
  loading?: boolean
  error?: ApolloError | undefined
  successMessage?: string
  successActionButton?: actionButton
  setError: Dispatch<SetStateAction<undefined>> | Dispatch<SetStateAction<ApolloError | undefined>>
  setOpen?: Dispatch<React.SetStateAction<boolean>>
  mergeSteps?: boolean
  submitLabel?: string
  autoCloseOnSuccess?: boolean
}

const mergeSchemas = (schemas: Array<ObjectSchema<ObjectShape>>): ObjectSchema<ObjectShape> => {
  const [first, ...rest] = schemas
  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first)
  return merged
}

export const FormikStep: React.FC<FormikStepProps> = ({ children }) => {
  return <>{children}</>
}

export const FormikStepper = <Values extends object>({
  children,
  setError,
  successMessage,
  successActionButton,
  error,
  setOpen,
  mergeSteps,
  submitLabel,
  autoCloseOnSuccess = false,
  ...props
}: FormikStepperProps<Values>): React.ReactElement => {
  const t = useAppTranslations()
  const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[]
  const [step, setStep] = React.useState(0)
  const currentChild = childrenArray[step]
  const [completed, setCompleted] = React.useState(false)

  const mergedValidationSchemas = useCallback(
    (): ObjectSchema<ObjectShape> =>
      mergeSchemas(childrenArray.map((children) => children.props.validationSchema as ObjectSchema<ObjectShape>)),
    [childrenArray]
  )

  const isLastStep = (): boolean => {
    return step === childrenArray.length - 1
  }

  const alertStyle = {
    maxWidth: 600,
    height: 250,
    // p: 3,
    fontSize: 20,
    mx: 'auto',
    '& .MuiAlert-icon	': {
      fontSize: '10rem',
      my: 'auto',
    },
    '& .MuiAlert-message': {
      my: 'auto',
    },
    '& 	.MuiAlert-action': {
      my: 'auto',
    },
  }

  const validationStep = (): JSX.Element | undefined => {
    if (error)
      return (
        <>
          <Alert severity="error" sx={alertStyle}>{`${t('commons.errorOccurred')}: ${error.message}`}</Alert>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCompleted(false)
                setError(undefined)
                setStep(0)
              }}
            >
              {t('commons.retry')}
            </Button>
            {setOpen && (
              <Button variant="contained" onClick={() => setOpen(false)}>
                {t('commons.actions.close')}
              </Button>
            )}
          </Stack>
        </>
      )
  }
  if (completed && !error)
    return (
      <Box sx={{ backgroundColor: '#f7faec', mx: '-34px', mb: '-25px', whiteSpace: 'pre-line' }}>
        <Alert
          sx={alertStyle}
          severity="success"
          action={
            successActionButton ? (
              <Button
                data-testid={successActionButton.testingId}
                key={successActionButton.label}
                variant="contained"
                size="large"
                onClick={successActionButton.onClick || undefined}
                startIcon={successActionButton.startIcon}
              >
                {t(successActionButton.label)}
              </Button>
            ) : undefined
          }
        >
          {successMessage}
        </Alert>
      </Box>
    )

  if (mergeSteps)
    return (
      <Formik<Values>
        {...props}
        validationSchema={mergedValidationSchemas}
        onSubmit={async (values, helpers) => {
          await props.onSubmit(values, helpers)
          if (autoCloseOnSuccess) setOpen && setOpen(false)
          else setCompleted(true)
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <>
            {completed || error ? (
              validationStep()
            ) : (
              <Form>
                {childrenArray.map((currentChild) => (
                  <Fragment key={currentChild.key}>
                    <SectionTitle title={currentChild.props.label} sx={{ mt: 4 }} />
                    {currentChild}
                  </Fragment>
                ))}
                <Box mt={4}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        startIcon={isSubmitting ? <CircularProgress size="1.6rem" /> : null}
                        disabled={isSubmitting || !isValid || !dirty}
                        variant="contained"
                        color="primary"
                        type="submit"
                        data-testid="formikStepperSubmit"
                      >
                        {isSubmitting ? t('commons.stepper.submitting') : submitLabel ?? t('commons.stepper.submit')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </>
        )}
      </Formik>
    )

  return (
    <>
      {childrenArray.length > 1 && (
        <Stepper sx={{ mt: 2 }} alternativeLabel activeStep={step}>
          {childrenArray.map((child, i) => (
            <Step key={i} completed={step > i || completed}>
              <StepLabel>{child.props.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <Formik<Values>
        {...props}
        validationSchema={currentChild.props.validationSchema}
        onSubmit={async (values, helpers) => {
          if (isLastStep()) {
            await props.onSubmit(values, helpers)
            setCompleted(true)
            autoCloseOnSuccess && setOpen && setOpen(false)
          } else {
            helpers.setTouched({})
            helpers.setSubmitting(false)
            setStep((step) => step + 1)
          }
        }}
      >
        {({ isSubmitting }) => (
          <>
            {completed || error ? (
              validationStep()
            ) : (
              <Form>
                {currentChild}
                <Box mt={4}>
                  <Grid container spacing={2}>
                    {step > 0 ? (
                      <Grid item>
                        <Button
                          disabled={isSubmitting}
                          variant="contained"
                          color="primary"
                          onClick={() => setStep((step) => step - 1)}
                        >
                          {t('commons.stepper.back')}
                        </Button>
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Button
                        startIcon={isSubmitting ? <CircularProgress size="1.6rem" /> : null}
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                        data-testid="formikStepperSubmit"
                      >
                        {isSubmitting
                          ? t('commons.stepper.submitting')
                          : isLastStep()
                          ? submitLabel ?? t('commons.stepper.submit')
                          : t('commons.stepper.next')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </>
        )}
      </Formik>
    </>
  )
}
